import React, {useEffect, useState} from 'react';
import AOS from "aos";
import Swiper from 'swiper';
import 'swiper/css';
import OffCanvasTuning from "../components/OffCanvasTuning";
import {useTranslation} from "react-i18next";
import {getAcademyList} from "../api/axios";
const Academy = () => {
  const {t, i18n} = useTranslation();
  const [academy, setAcademy] = useState([]);

  useEffect(() => {
    document.title = 'Asferrari - Academy';
    AOS.init({
      delay: 100,
      duration: 1000,
    });
    AOS.refresh();

    getAcademyList().then(value => setAcademy(value?.data?.data))
  }, []);

  function onRoadLinkClick(e) {
    document.querySelectorAll(".road__list-link").forEach((value, key) => {
      value.classList.remove("active");
    })
    e.target.classList.add("active")
  }

  return (
    <>
     <OffCanvasTuning />

      <main>
        <div className="road">
          <div className="container-fluid position-relative">
            <ul className="road__list">
              <li className="road__list-item"><a onClick={onRoadLinkClick} className="road__list-link active" href={"#intro"}></a></li>
              <li className="road__list-item"><a onClick={onRoadLinkClick} className="road__list-link" href={"#blog"}></a></li>
              <li className="road__list-item"><a onClick={onRoadLinkClick} className="road__list-link" href={"#references"}></a></li>
            </ul>
          </div>
        </div>

        <section id="intro" className="intro">
          <img src={"/assets/images/academy-bg.png"} alt="background" className="intro__background"/>
          <div data-aos="fade-right" className="container h-100">
            <div className="intro__content">
              <h1 className="intro__headline">{t('stay-updated')}</h1>
              <p className="intro__description">
                {t('follow-up')}
              </p>
              <a href="#blog" className="intro__link">
                <span>{t('read-more')}</span>
                <div className="intro__link-icon">
                  <img src={"/assets/icons/right.svg"} alt="right icon"/>
                </div>
              </a>
            </div>
          </div>
        </section>

        <section id="blog" className="blog">
          <div data-aos="fade-left" className="container">
            <h2 className="title__center">
              {t('today')}<br/>
              <span>{t('newspaper')}</span>
            </h2>
            <div className="store__wrapper row g-4">
              {
                academy?.map((value, index) => {
                  return (
                    <div className="col-12 col-md-6 col-lg-4" key={index}>
                      <a href={`/academy-detail/${value?.id}`}>
                        <div className="card__primary">
                          <div className="card__primary-img">
                            <img src={"data:image/png;base64, " + value?.academyPicture} alt="Faster with new Chip technology"/>
                          </div>
                          <div className="d-flex align-items-center gap-2 justify-content-between">
                            <span className="card__primary-title w-100">{value?.title}</span>
                          </div>
                          <p className="card__primary-description">{value?.text?.substring(0, 100)}...</p>
                          <button type="button" className="btn btn-dark card__primary-btn">
                            {t('read-now')}
                            <img src={"/assets/icons/right.svg"} height="16" alt="right"/>
                          </button>
                        </div>
                      </a>
                    </div>
                  )
                } )
              }

            </div>
          </div>
        </section>

        <section id="references" className="references">
          <div data-aos="fade-right" className="container">
            <div className="swiper referencesSwiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide references__item">
                  <img src={"/assets/images/ferrari.png"} alt="ferrari logo"/>
                </div>
                <div className="swiper-slide references__item">
                  <img src={"/assets/images/tesla.png"} alt="tesla logo"/>
                </div>
                <div className="swiper-slide references__item">
                  <img src={"/assets/images/maserati.png"} alt="maserati logo"/>
                </div>
                <div className="swiper-slide references__item">
                  <img src={"/assets/images/bugatti.png"} alt="bugatti logo"/>
                </div>
                <div className="swiper-slide references__item">
                  <img src={"/assets/images/lamborghini-alt.png"} alt="lamborghini logo"/>
                </div>
                <div className="swiper-slide references__item">
                  <img src={"/assets/images/land-rover.png"} alt="land rover logo"/>
                </div>
                <div className="swiper-slide references__item">
                  <img src={"/assets/images/bmw.png"} alt="bmw logo"/>
                </div>
                <div className="swiper-slide references__item">
                  <img src={"/assets/images/audi.png"} alt="audi logo"/>
                </div>
                <div className="swiper-slide references__item">
                  <img src={"/assets/images/mercedes-benz-alt.png"} alt="mercedes benz logo"/>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default Academy