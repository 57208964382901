import React from 'react';
import { useTranslation } from "react-i18next";
import TrustBox from './TrustBox';

const Footer = ({ data }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="footer__wrapper">
            <div className="footer__about">
              <img src="/assets/images/logo.png" width={"200"} height={"41"} alt="AsFerrari chip tuning" />
              <p className="footer__description">
                <div className="d-flex mt-2 gap-3 align-items-center justify-content-end flex-wrap">
                  <a target="_blank" href="https://www.paypal.com/ncp/payment/88Z8RR5BMVWXU">
                    <button type="button" className="btn btn-paypal">
                      {t("buy-with")}
                      <img width="60" height="14.5" src="/assets/icons/paypal.svg" alt="paypal" />
                      <img width="8" height="16" src="/assets/icons/right.svg" alt="right" />
                    </button>
                  </a>
                </div>
              </p>

              <span className="footer__subtitle flex-column align-items-start mt-1">
                {/* <img width="20" height="20" src="/assets/icons/world.svg" alt="International icon"/>
                {t('international')} */}
                <p>{t('our-partners')}</p>
                <a href="https://www.autopazar.me/" target="_blank" rel="noopener noreferrer"><img src="/assets/images/autopazar.png" width={"200"} height={"41"} alt="Autopazar.me" /></a>
              </span>
            </div>

            <div className="footer__menu">
              <ul className="footer__menu-list">
                <li><span className="footer__menu-title">{t('help')}</span></li>
                <li><a href="/academy" className="footer__menu-item">{t('academy')}</a></li>
                <li><a href="/about-us" className="footer__menu-item">{t('about-us')}</a></li>
                <li><a href="/about-us" className="footer__menu-item">{t('contact')}</a></li>
                <li><a href="http://asftuning.com/" className="footer__menu-item">{t('blog')}</a></li>
                <li><a href="#" className="footer__menu-sublink">{t('cookie-policy')}</a></li>
              </ul>
              <ul className="footer__menu-list">
                <li><span className="footer__menu-title">{t('explore')}</span></li>
                <li><a href="/tuning" className="footer__menu-item">{t('go-tuning')}</a></li>
                <li><a href="/services" className="footer__menu-item">{t('services')}</a></li>
                <li><a href="/store" className="footer__menu-item">{t('store')}</a></li>
                <li><a href="/privacy-policy" className="footer__menu-sublink">{t('privacy-policy')}</a></li>
              </ul>
            </div>

            <div className="footer__social">
              <div className="footer__social-title">{t('social-links')}</div>
              <div className="footer__social-links">
                {
                  data?.instagramUrl ? (
                    <a href={data?.instagramUrl}>
                      <div className="footer__social-item">
                        <i className="fa-brands fa-instagram"></i>
                      </div>
                    </a>
                  ) : ''
                }
                {
                  data?.whatsappNo ? (
                    <a href={"https://wa.me/" + data?.whatsappNo?.replaceAll(' ', '')}>
                      <div className="footer__social-item">
                        <i className="fa-brands fa-whatsapp"></i>
                      </div>
                    </a>
                  ) : ''
                }
                {
                  data?.metaUrl ? (
                    <a href={data?.metaUrl}>
                      <div className="footer__social-item">
                        <i className="fa-brands fa-meta"></i>
                      </div>
                    </a>
                  ) : ''
                }
                {
                  data?.tiktokUrl ? (
                    <a href={data?.tiktokUrl}>
                      <div className="footer__social-item">
                        <i className="fa-brands fa-tiktok"></i>
                      </div>
                    </a>
                  ) : ''
                }
                {
                  data?.twitterUrl ? (
                    <a href={data?.twitterUrl}>
                      <div className="footer__social-item">
                        <i className="fa-brands fa-x-twitter"></i>
                      </div>
                    </a>
                  ) : ''
                }
              </div>
              <TrustBox></TrustBox>
              <div className="footer__social-title">{t('contact-fast')}</div>
              <ul className="footer__contact">
                <li className="footer__contact-item footer__contact-item-black">
                  <img src="/assets/icons/mobile-notch-1.svg" width="20" height="20" alt="mobile icon" />
                  <span> <a href={"https://wa.me/" + data?.whatsappNo?.replaceAll(' ', '').replaceAll('+', '')}>{data?.phoneNumber}</a></span>
                </li>
                <li className="footer__contact-item">
                  <img src="/assets/icons/locaiton.svg" width="20" height="20" alt="locaiton icon" />
                  <span>
                    <a className="map" target="_blank" href={"http://maps.google.com/?q=" + encodeURIComponent(data?.address)}>{data?.address}</a>
                  </span>
                </li>
                <li>
                  <div className="store__box__footer">
                    <a href="https://apps.apple.com/hr/app/asferrari/id6504839393"><img src="/assets/images/apple-store-logo.png" className='img-fluid' alt="AsFerrari apple store" /></a>
                    <a href="https://play.google.com/store/apps/details?id=com.asferrari.app"><img src="/assets/images/google-play-badge-logo.png" className='img-fluid' alt="AsFerrari google play" /></a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer__copyright">
            <p>{t('all-rights-reserved')}</p>
          </div>
        </div>
      </footer>
    </>
  )

}

export default Footer