import React, {useEffect, useState} from 'react';
import AOS from "aos";
import 'swiper/css';
import OffCanvasTuning from "../components/OffCanvasTuning";
import {useTranslation} from "react-i18next";
import {getAbout} from "../api/axios";

const AboutUs = () => {
  const {t, i18n} = useTranslation();
  const [about, setAbout] = useState("");

  useEffect(() => {
    document.title = 'Asferrari - About Us';
    AOS.init({
      delay: 100,
      duration: 1000,
    });
    AOS.refresh();

    getAbout().then(value => setAbout(value?.data?.data))
  }, []);

  function onRoadLinkClick(e) {
    document.querySelectorAll(".road__list-link").forEach((value, key) => {
      value.classList.remove("active");
    })
    e.target.classList.add("active")
  }
  return (
    <>
      <OffCanvasTuning />

      <main>
        <div className="road">
          <div className="container-fluid position-relative">
            <ul className="road__list">
              <li className="road__list-item"><a onClick={onRoadLinkClick} className="road__list-link active" href="#intro"></a></li>
              <li className="road__list-item"><a onClick={onRoadLinkClick} className="road__list-link" href="#about"></a></li>
              <li className="road__list-item"><a onClick={onRoadLinkClick} className="road__list-link" href="#stats"></a></li>
              <li className="road__list-item"><a onClick={onRoadLinkClick} className="road__list-link" href="#founder"></a></li>
              <li className="road__list-item"><a onClick={onRoadLinkClick} className="road__list-link" href="#references"></a></li>
            </ul>
          </div>
        </div>

        <section id="intro" className="intro">
          <img src={"/assets/images/about-bg.png"} alt="background" className="intro__background"/>
          <div data-aos="fade-right" className="container h-100">
            <div className="intro__content">
              <h1 className="intro__headline">{about?.mainTitle}</h1>
              <p className="intro__description">
                {about?.mainDesc}
              </p>
              <a href="#about" className="intro__link">
                <span>{t('read-more')}</span>
                <div className="intro__link-icon">
                  <img src={"/assets/icons/right.svg"} alt="right icon"/>
                </div>
              </a>
            </div>
          </div>
        </section>

        <section id="about" className="about">
          <div data-aos="fade-left" className="container">
            <div className="about__content">
              <h2 className="about__headline">{about?.title1}</h2>
              <p className="about__description">
                {about?.text1}
              </p>
            </div>
          </div>
        </section>

        <section id="stats" className="stats">
          <div data-aos="fade-right" className="container">
            <div className="stats__wrapper">
              <div className="row g-5">
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="stats__card">
                    <div className="stats__card-icon">
                      <img width="60" height="60" src={"/assets/icons/emerging-industries.png"} alt="COUNTRIES COVERED"/>
                    </div>
                    <span className="stats__card-count">{about?.award1Title}</span>
                    <span className="stats__card-title">{about?.award1Number}</span>
                    <span className="stats__card-subtitle">{about?.award1Text}</span>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="stats__card">
                    <div className="stats__card-icon">
                      <img width="60" height="60" src={"/assets/icons/rise.png"} alt="COSTUMER ASCENSION"/>
                    </div>
                    <span className="stats__card-count">{about?.award2Title}</span>
                    <span className="stats__card-title">{about?.award2Number}</span>
                    <span className="stats__card-subtitle">{about?.award2Text}</span>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="stats__card">
                    <div className="stats__card-icon">
                      <img width="60" height="60" src={"/assets/icons/verified.png"} alt="CERTIFICATE ACHIEVEMENT"/>
                    </div>
                    <span className="stats__card-count">{about?.award3Title}</span>
                    <span className="stats__card-title">{about?.award3Number}</span>
                    <span className="stats__card-subtitle">{about?.award3Text}</span>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="stats__card">
                    <div className="stats__card-icon">
                      <img width="60" height="60" src={"/assets/icons/engine.png"} alt="UPGRADED VEHICLE"/>
                    </div>
                    <span className="stats__card-count">{about?.award4Title}</span>
                    <span className="stats__card-title">{about?.award4Number}</span>
                    <span className="stats__card-subtitle">{about?.award4Text}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="founder" className="founder">
          <div data-aos="fade-left" className="container">
            <div className="founder__wrapper">
              <div className="founder__about">
                <h3 className="founder__headline">{t('word-founder')}</h3>
                <p className="founder__description">
                  {about?.wordOfFounder}
                </p>
                <div className="founder__signature">
                  <img width="250" height="105" src={"/assets/images/durovic-signature.png"} alt="signature"/>
                </div>
                <span className="founder__title">Djordjije Durovic - CEO Asferrari</span>
              </div>
              <div className="founder__img">
                <img src={"/assets/images/founder.png"} alt="AsFerrari tuning"/>
                <div className="founder__badge">
                  <img src={"/assets/images/badge.png"} width="195" height="144" alt="AsFerrari tuning"/>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="references" className="references">
          <div data-aos="fade-right" className="container">
            <div className="swiper referencesSwiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide references__item">
                  <img src={"/assets/images/ferrari.png"} alt="ferrari logo"/>
                </div>
                <div className="swiper-slide references__item">
                  <img src={"/assets/images/tesla.png"} alt="tesla logo"/>
                </div>
                <div className="swiper-slide references__item">
                  <img src={"/assets/images/maserati.png"} alt="maserati logo"/>
                </div>
                <div className="swiper-slide references__item">
                  <img src={"/assets/images/bugatti.png"} alt="bugatti logo"/>
                </div>
                <div className="swiper-slide references__item">
                  <img src={"/assets/images/lamborghini-alt.png"} alt="lamborghini logo"/>
                </div>
                <div className="swiper-slide references__item">
                  <img src={"/assets/images/land-rover.png"} alt="land rover logo"/>
                </div>
                <div className="swiper-slide references__item">
                  <img src={"/assets/images/bmw.png"} alt="bmw logo"/>
                </div>
                <div className="swiper-slide references__item">
                  <img src={"/assets/images/audi.png"} alt="audi logo"/>
                </div>
                <div className="swiper-slide references__item">
                  <img src={"/assets/images/mercedes-benz-alt.png"} alt="mercedes benz logo"/>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default AboutUs