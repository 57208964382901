"use client";
import React, { useEffect, useState } from 'react';
import AOS from "aos";
import 'swiper/css';
import OffCanvasTuning from "../components/OffCanvasTuning";
import { useTranslation } from "react-i18next";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  InfoWindow
} from "@vis.gl/react-google-maps";
import { getDealerList } from "../api/axios";

const Dealers = () => {
  const { t, i18n } = useTranslation();
  const [markers, setMarkers] = useState([]);

  const mapStyle = [
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#263c3f" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#6b9a76" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#38414e" }],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#212a37" }],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9ca5b3" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#746855" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#1f2835" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#f3d19c" }],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{ color: "#2f3948" }],
    },
    {
      featureType: "transit.station",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#17263c" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#515c6d" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#17263c" }],
    },
  ];

  const position = { lat: 42.560604004266494, lng: 19.100116524226724 };



  useEffect(() => {
    document.title = 'Asferrari - Dealers';
    AOS.init({
      delay: 100,
      duration: 1000,
    });
    AOS.refresh();
    getDealerList().then(value => setMarkers(value?.data?.data))
  }, []);

  function onRoadLinkClick(e) {
    document.querySelectorAll(".road__list-link").forEach((value, key) => {
      value.classList.remove("active");
    })
    e.target.classList.add("active")
  }

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker)
      return;
    setActiveMarker(marker);
  }

  return (
    <>
      <OffCanvasTuning />

      <main>
        <div className="road">
          <div className="container-fluid position-relative">
            <ul className="road__list">
              <li className="road__list-item"><a onClick={onRoadLinkClick} className="road__list-link active" href={"#intro"}></a></li>
              <li className="road__list-item"><a onClick={onRoadLinkClick} className="road__list-link" href={"#map"}></a></li>
            </ul>
          </div>
        </div>

        <section id="intro" className="intro">
          <img src={"/assets/images/academy-bg.png"} alt="background" className="intro__background" />
          <div data-aos="fade-right" className="container h-100">
            <div className="intro__content">
              <h1 className="intro__headline">{t('dealers')}</h1>
              <p className="intro__description">
                {t('dealers-map')}
              </p>
              <a href="#map" className="intro__link">
                <span>{t('read-more')}</span>
                <div className="intro__link-icon">
                  <img src={"/assets/icons/right.svg"} alt="right icon" />
                </div>
              </a>
            </div>
          </div>
        </section>

        <section id="map" className="blog">
          <div data-aos="fade-left" className="container">
            <APIProvider apiKey='AIzaSyBonp6UkWccLe-qAT1Pm44f1JZzajWef68'>
              <div style={{ height: "50vh", width: "100%" }}>
                {/* <Map zoom={9} center={position} mapId={'fd6b96db192b1555'} /> */}
                <Map
                  defaultCenter={position}
                  defaultZoom={3}
                  gestureHandling={'greedy'}
                  disableDefaultUI={true}
                  mapId={'fd6b96db192b1555'}
                  styles={mapStyle}

                >
                  {
                    markers.map(marker => {
                      let position = {"lat": +marker.lat, "lng": +marker.lng};
                      return (
                        <AdvancedMarker position={position} key={marker.id} onClick={() => handleActiveMarker(marker.id)}>
                          {
                            activeMarker === marker.id ?
                              <InfoWindow
                                position={position}
                                onCloseClick={() => setActiveMarker(null)}
                              >
                                <ul className={"list-group"}>
                                  <li className={"list-group-item"}>{marker.name}</li>
                                  <li className={"list-group-item"}>{marker.phone}</li>
                                </ul>
                              </InfoWindow>
                              :
                              null
                          }
                        </AdvancedMarker>
                      )
                    })
                  }

                </Map>
              </div>
            </APIProvider>
          </div>
        </section>
      </main>
    </>
  )
}
export default Dealers