import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      "engine": "Engine",
      "year": "Year",
      "about-us-1": "About",
      "about-us-2": "us",
      "about-us-welcome-to": "Welcome to Asferrari - Redefining Performance Excellence",
      "read-more": "Read More",
      "read-now": "Read Now",
      "by-asferrari-users": "By Asferrari users",
      "COSTUMER-ASCENSION": "COSTUMER ASCENSION",
      "from-2019": "From 2019",
      "CERTIFICATE-ACHIEVEMENT": "CERTIFICATE ACHIEVEMENT",
      "UPGRADED-VEHICLE": "UPGRADED VEHICLE",
      "word-founder": "Word of the Founder",
      "word-founder-1": "We've turned our love for cars into expertise through years of hard work and extensive training. Our aim is to offer top-notch service, not only in Montenegro but also internationally. AsFerrari is now a leading service in our region.",
      "word-founder-2": "We have our own suppliers and provide training for those interested in this field.",
      "lets-tune-your-car": "Let’s Tune Your Car",
      "similar-cars": "Similar Cars",
      "with-asferrari-tuned": "With Asferrari Tuned",
      "tuning-audi-details": "Experience precision and performance at Asferrari. Unleash a new level of power, responsiveness, and efficiency",
      "horse-power": "HORSE POWER",
      "factory": "Factory",
      "torque": "TORQUE",
      "buy-with": "Pay",
      "for-stage-1": "For Stage 1",
      "asferrari-tuned": "Asferrari Tuned",
      "6-reason": "6 reasons to",
      "choose-asfferari": "choose Asfferari",
      "kilometer": "Km",
      "today": "Today",
      "newspaper": "Newspaper",
      "stay-updated": "Stay Updated Our Academy",
      "follow-up": "Follow the most up-to-date information prepared by our experienced team",
      "select-car": "Select Car",
      "where-speed-meets": "Where Speed Meets Precision",
      "where-speed-meets-sub": "Experience precision and performance at Asferrari. Unleash a new level of power, responsiveness, and efficiency",
      "chip-tuning-leader": "Chip Tuning Market Leader",
      "chip-tuning-leader-sub": "#1 Chip Performance",
      "horse-torque": "Increased Horsepower and Torque",
      "horse-torque-sub": "Overall performance",
      "enhanced-fuel": "Enhanced Fuel Efficiency",
      "enhanced-fuel-sub": "Improved fuel economy.",
      "throttle": "Improved Throttle Response",
      "throttle-sub": "Now more sensitive",
      "latest-software": "Latest software system",
      "latest-software-sub": "Quality Technology.",
      "warranty": "Up to 2 years engine warranty",
      "warranty-sub": "Trusted company",
      "check-our-store": "Check our store",
      "join-club-today": "Join Asferrari Club Today!",
      "more-equipment": "More equipments",
      "services-welcome": "Welcome to Asferrari - Redefining Performance Excellence",
      "faq": "FAQ",
      "model": "Model",
      "home": "Home",
      "services": "Services",
      "academy": "Academy",
      "store": "Store",
      "about-us": "About Us",
      "tune-car": "Tune Car",
      "help": "Help",
      "contact": "Contact",
      "blog": "Blog",
      "cookie-policy": "Cookie Policy",
      "explore": "Explore",
      "go-tuning": "Go Tuning",
      "privacy-policy": "Privacy Policy",
      "social-links": "Social Links",
      "contact-fast": "Contact Fast",
      "all-rights-reserved": "All Right Reserved © Asferrari 2023",
      "international": "International",
      "buy-now": "Buy Now",
      "dealers": "Dealers",
      "dealers-map": "Map of our dealers all over the world",
      "log-in": "Login",
      "our-partners": "Our partners"
    }
  },
  cnr: {
    translation: {
      "engine": "Motor",
      "year": "Godina",
      "about-us-1": "O nama",
      "about-us-2": "mi",
      "about-us-welcome-to": "Dobrodošli u Asferrari - Definisanje izvrsnosti performansi",
      "read-more": "Pročitaj više",
      "read-now": "Pročitaj sada",
      "by-asferrari-users": "Od strane korisnika Asferrarija",
      "COSTUMER-ASCENSION": "USPON KUPCA",
      "from-2019": "Od 2019. godine",
      "CERTIFICATE-ACHIEVEMENT": "SERTIFIKAT POSTIGNUĆA",
      "UPGRADED-VEHICLE": "NADOGRAĐENO VOZILO",
      "word-founder": "Reč osnivača",
      "word-founder-1": "Svoju ljubav prema automobilima pretvorili smo u ekspertizu kroz godine rada i obuke. Naš cilj je pružiti vrhunsku uslugu, ne samo u Crnoj Gori, već i internacionalno. AsFerrari je sada vodeća usluga u našem regionu.",
      "word-founder-2": "Imamo svoje dobavljače i pružamo obuku onima koji su zainteresovani za ovo područje.",
      "lets-tune-your-car": "Podesimo vaš automobil",
      "similar-cars": "Slični automobili",
      "with-asferrari-tuned": "Sa Asferrari podešavanjem",
      "tuning-audi-details": "Iskusite preciznost i performanse u Asferrariju. Oslobodite novi nivo snage, odziva i efikasnosti.",
      "horse-power": "KONJSKA SNAGA",
      "factory": "Fabrika",
      "torque": "Obrtni moment",
      "buy-with": "Plati",
      "for-stage-1": "Za fazu 1",
      "asferrari-tuned": "Asferrari podešeno",
      "6-reason": "6 razloga za",
      "choose-asfferari": "izabrati Asferari",
      "kilometer": "Km",
      "today": "Danas",
      "newspaper": "Novine",
      "stay-updated": "Ostanite ažurirani - Naša akademija",
      "follow-up": "Pratite najnovije informacije pripremljene od strane našeg iskusnog tima",
      "select-car": "Izaberite automobil",
      "where-speed-meets": "Gde brzina susreće preciznost",
      "where-speed-meets-sub": "Iskusite preciznost i performanse u Asferrariju. Oslobodite novi nivo snage, odziva i efikasnosti.",
      "chip-tuning-leader": "Lider na tržištu čip tuniranja",
      "chip-tuning-leader-sub": "#1 Čip performansi",
      "horse-torque": "Povećana snaga i obrtni moment",
      "horse-torque-sub": "Ukupne performanse",
      "enhanced-fuel": "Poboljšana efikasnost goriva",
      "enhanced-fuel-sub": "Poboljšana ekonomija goriva.",
      "throttle": "Poboljšan odziv papučice gasa",
      "throttle-sub": "Sada osetljivije",
      "latest-software": "Najnoviji softverski sistem",
      "latest-software-sub": "Kvalitetna tehnologija.",
      "warranty": "Do 2 godine garancije na motor",
      "warranty-sub": "Pouzdana kompanija",
      "check-our-store": "Proverite našu prodavnicu",
      "join-club-today": "Pridružite se Asferrari klubu danas!",
      "more-equipment": "Više opreme",
      "services-welcome": "Dobrodošli u Asferrari - Definisanje izvrsnosti performansi",
      "faq": "Često postavljana pitanja",
      "model": "Model",
      "home": "Početna",
      "services": "Usluge",
      "academy": "Akademija",
      "store": "Prodavnica",
      "about-us": "O nama",
      "tune-car": "Podesi automobil",
      "help": "Pomoć",
      "contact": "Kontakt",
      "blog": "Blog",
      "cookie-policy": "Politika kolačića",
      "explore": "Istraži",
      "go-tuning": "Idi na podešavanje",
      "privacy-policy": "Politika privatnosti",
      "social-links": "Društvene mreže",
      "contact-fast": "Brzi kontakt",
      "all-rights-reserved": "Sva prava zadržana © Asferrari 2023",
      "international": "Međunarodno",
      "buy-now": "Kupi sada",
      "dealers": "Dileri",
      "dealers-map": "Mapa naših dilera širom svijeta",
      "log-in": "Login",
      "our-partners": "Naši partneri"
    }

  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    }
  })
  .then(r => {});

export default i18n;