import React, { useEffect, useState } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper/modules";
import OffCanvasTuning from "../components/OffCanvasTuning";
import { getMakes, getModelsByBrandName, getProducts, getTuningByAllData, getYearsByBrandName, getModelsByMake } from "../api/axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  const [brands, setBrands] = useState([]);

  const [selectedBrand, setSelectedBrand] = useState([]);
  const [brandName, setBrandName] = useState("");

  const [selectedModel, setSelectedModel] = useState({});
  const [modelName, setModelName] = useState("");

  const [motorOptions, setEngineOptions] = useState([]);
  const [engineName, setEngineName] = useState("");

  const [yearOptions, setYearOptions] = useState([]);
  const [year, setYear] = useState("");
  const [products, setProducts] = useState([]);

  const [tuningData, setTuningData] = useState("");

  useEffect(() => {
    document.title = 'Asferrari - Home';
    AOS.init({
      delay: 100,
      duration: 1000,
    });
    AOS.refresh();
    updateCounters();

    setBrandName(t('select-car'))
    setModelName(t('model'))
    setEngineName(t('engine'))
    setYear(t('year'))

    getMakes()
      .then(value => setBrands(value?.data))

    getProducts()
      .then(value => setProducts(value?.data?.data))
  }, []);

  function onRoadLinkClick(e) {
    document.querySelectorAll(".road__list-link").forEach((value, key) => {
      value.classList.remove("active");
    })
    e.target.classList.add("active")
  }

  function getYearsByBrandAndModel(model) {
    getYearsByBrandName(brandName, model)
      .then(value => {
        setYearOptions(value?.data)
      })
  }

  function getModelsByMakeName(make) {
    getModelsByMake(make)
      .then(value => {
        console.log(value);
        setSelectedBrand(value?.data)
      })
  }

  function getTuning(engineName) {
    getTuningByAllData(brandName, modelName, year, engineName)
      .then(value => {
        setTuningData(value?.data)
        console.log(value?.data)
      })
  }

  function goToTuning(e) {
    if (brandName !== "" && modelName != "" & year !== "" & engineName !== "") {
      //navigate('/tuning',{state: {brandName, modelName, year, engineName}});
      window.location.href = `/tuning/${encodeURIComponent(brandName)}/${encodeURIComponent(modelName)}/${encodeURIComponent(year)}/${encodeURIComponent(engineName)}`;
    }
  }

  function getMotorsByBrandAndModelAndYear(year) {
    getModelsByBrandName(brandName, modelName, year)
      .then(value => {
        setEngineOptions(value?.data)
      })
  }

  function updateCounters() {
    const myNum = document.querySelectorAll(".count");
    const speed = 150;

    myNum.forEach((myCount) => {
      let target_count = myCount.dataset.count;
      target_count = parseInt(target_count);
      let init_count = 0;

      const updateNumber = () => {
        init_count += Math.ceil(target_count / speed);
        if (init_count > target_count) {
          init_count = target_count;
        }

        myCount.innerText = `${init_count}`;

        if (init_count < target_count) {
          requestAnimationFrame(updateNumber);
        }
      };

      updateNumber();
    });
  }

  return (
    <>
      <OffCanvasTuning />

      <main>
        <div className="road">
          <div className="container-fluid position-relative">
            <ul className="road__list">
              <li className="road__list-item"><a onClick={onRoadLinkClick} className="road__list-link active" href={"#intro"}></a></li>
              <li className="road__list-item"><a onClick={onRoadLinkClick} className="road__list-link" href={"#reasons"}></a></li>
              <li className="road__list-item"><a onClick={onRoadLinkClick} className="road__list-link" href={"#store"}></a></li>
              <li className="road__list-item"><a onClick={onRoadLinkClick} className="road__list-link" href={"#founder"}></a></li>
              <li className="road__list-item"><a onClick={onRoadLinkClick} className="road__list-link" href={"#references"}></a></li>
            </ul>
          </div>
        </div>

        <section id="intro" className="intro">
          <img src="/assets/images/home-car.gif" alt="background" className="intro__background" />
          <div data-aos="fade-left" className="container h-100">
            <div className="store__box">
              <a href="https://apps.apple.com/hr/app/asferrari/id6504839393"><img src="/assets/images/apple-store-logo.png" className='img-fluid' alt="AsFerrari apple store" /></a>
              <a href="https://play.google.com/store/apps/details?id=com.asferrari.app"><img src="/assets/images/google-play-badge-logo.png" className='img-fluid' alt="AsFerrari google play" /></a>
            </div>
            <div className="intro__content">
              <h1 className="intro__headline">{t('where-speed-meets')}</h1>
              <p className="intro__description">{t('where-speed-meets-sub')}</p>
              <a href="#" className="intro__link" data-bs-toggle="offcanvas" data-bs-target="#tuneCarMenu" aria-controls="tuneCarMenu">
                <span>{t('lets-tune-your-car')}</span>
                <div className="intro__link-icon">
                  <img src="/assets/icons/right.svg" alt="right icon" />
                </div>
              </a>
            </div>
            <div className="intro__km">
              <img className="intro__km-icon" width="12" height="22" src="/assets/icons/lightning.svg" alt="lightning icon" />
              <span className="intro__km-count count" data-count="360">0</span>
              <span className="intro__km-name">{t('kilometer')}</span>
            </div>
          </div>

          <div data-aos="fade-right" className="filter">
            <div className="container">
              <div className="filter__wrapper">
                <div className="dropdown">
                  <button className="select__btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span data-text="selectCar" className="select__text">{brandName}</span>
                    <img src="/assets/icons/bottom.svg" alt="bottom icon" />
                  </button>
                  <ul className="dropdown-menu select__menu">
                    {
                      brands.map((value, index) => {
                        return (
                          <li key={index}>
                            <button className="dropdown-item select__menu-item"
                              type="button"
                              onClick={e => {
                                setBrandName(value);
                                getModelsByMakeName(value);
                                setModelName("Model")

                                setModelName(t('model'))
                                setEngineName(t('engine'))
                                setYear(t('year'))
                              }}>
                              {value}
                              <img src="/assets/icons/right.svg" width="8" height="16" alt="right icon" />
                            </button>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
                <div className="dropdown">
                  <button className="select__btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span data-text="selectCar" className="select__text">{modelName}</span>
                    <img src="/assets/icons/bottom.svg" alt="bottom icon" />
                  </button>
                  <ul className="dropdown-menu select__menu">
                    {
                      selectedBrand?.map((value, index) => {
                        return (
                          <li key={index}>
                            <button className="dropdown-item select__menu-item"
                              type="button"
                              onClick={e => {
                                setModelName(value);
                                setSelectedModel(value);
                                getYearsByBrandAndModel(value);

                                setEngineName(t('engine'))
                                setYear(t('year'))
                              }}>
                              {value}
                              <img src="/assets/icons/right.svg" width="8" height="16" alt="right icon" />
                            </button>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
                <div className="dropdown">
                  <button className="select__btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span data-text="selectCar" className="select__text">{year}</span>
                    <img src="/assets/icons/bottom.svg" alt="bottom icon" />
                  </button>
                  <ul className="dropdown-menu select__menu">
                    {
                      yearOptions?.map((value, index) => {
                        return (
                          <li key={index}>
                            <button className="dropdown-item select__menu-item"
                              type="button"
                              onClick={e => {
                                setYear(value)
                                getMotorsByBrandAndModelAndYear(value);
                                setEngineName(t('engine'))
                              }}>
                              {value}
                              <img src="/assets/icons/right.svg" width="8" height="16" alt="right icon" />
                            </button>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
                <div className="dropdown">
                  <button className="select__btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span data-text="selectCar" className="select__text">{engineName}</span>
                    <img src="/assets/icons/bottom.svg" alt="bottom icon" />
                  </button>
                  <ul className="dropdown-menu select__menu">
                    {
                      motorOptions?.map((value, index) => {
                        return (
                          <li key={index}>
                            <button className="dropdown-item select__menu-item"
                              type="button"
                              onClick={e => {
                                setEngineName(value);
                                //getTuning(value);
                              }}>
                              {value}
                              <img src="/assets/icons/right.svg" width="8" height="16" alt="right icon" />
                            </button>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
                <button className="btn btn-danger" onClick={goToTuning}>
                  {t("lets-tune-your-car")}
                  <img src="/assets/icons/right.svg" alt="right" />
                </button>
              </div>
            </div>
          </div>

        </section>

        <section id="reasons" className="reasons">
          <div data-aos="fade-left" className="container">
            <h2 className="reasons__headline"><span className="fw-bold">{"6-reason"}</span> <br /> {"choose-asfferari"}</h2>

            <div className="reasons__content">
              <div className="swiper reasonSwiper">
                <Swiper
                  spaceBetween={30}
                  slidesPerView={1}
                  modules={[Navigation]}
                  navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                  }}
                  breakpoints={{
                    578: {
                      width: 578,
                      slidesPerView: 2,
                    },
                    768: {
                      width: 768,
                      slidesPerView: 3,
                    },
                    1024: {
                      width: 1024,
                      slidesPerView: 4,
                    },
                  }}
                >
                  <div className="swiper-wrapper reasons__wrapper">
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="reasons__item">
                          <div className="reasons__item-icon">
                            <img src="/assets/icons/winning.svg" alt="Chip Tuning Market Leader" />
                          </div>
                          <span className="reasons__item-headline">{t('chip-tuning-leader')}</span>
                          <p className="reasons__item-tag">{t('chip-tuning-leader-sub')}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="reasons__item">
                          <div className="reasons__item-icon">
                            <img src="/assets/icons/speedmeter.svg" alt="speedmeter icon" />
                          </div>
                          <span className="reasons__item-headline">{t('horse-torque')}</span>
                          <p className="reasons__item-tag">{t('horse-torque-sub')}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="reasons__item">
                          <div className="reasons__item-icon">
                            <img src="/assets/icons/oil-spill.svg" alt="oil-spill icon" />
                          </div>
                          <span className="reasons__item-headline">{t('enhanced-fuel')}</span>
                          <p className="reasons__item-tag">{t('enhanced-fuel-sub')}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="reasons__item">
                          <div className="reasons__item-icon">
                            <img src="/assets/icons/speedometer-2.svg" alt="speedometer icon" />
                          </div>
                          <span className="reasons__item-headline">{t('throttle')}</span>
                          <p className="reasons__item-tag">{t('throttle-sub')}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="reasons__item">
                          <div className="reasons__item-icon">
                            <img src="/assets/icons/cpu.svg" alt="Latest software system icon" />
                          </div>
                          <span className="reasons__item-headline">{t('latest-software')}</span>
                          <p className="reasons__item-tag">{t('latest-software-sub')}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="reasons__item">
                          <div className="reasons__item-icon">
                            <img src="/assets/icons/shield.svg" alt="shield icon" />
                          </div>
                          <span className="reasons__item-headline">{t('warranty')}</span>
                          <p className="reasons__item-tag">{t('warranty-sub')}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  </div>
                </Swiper>
              </div>
              <div ref={navigationNextRef} className="reasons__next">
                <img src="/assets/icons/right.svg" alt="next" />
              </div>
              <div ref={navigationPrevRef} className="reasons__prev">
                <img src="/assets/icons/left.svg" alt="prev" />
              </div>
            </div>
          </div>
        </section>

        <section id="store" data-aos="fade-right" className="store">
          <div className="container">
            <div className="d-block d-md-flex align-items-end gap-2 justify-content-between">
              <h3 className="store__headline">
                <span>{t('check-our-store')}</span>
                {t('join-club-today')}
              </h3>
              <a href="/store" className="store__link">
                <span>{t('more-equipment')}</span>
                <img src="/assets/icons/right.svg" alt="right" />
              </a>
            </div>

            <div className="store__wrapper row g-4">
              {
                products?.map((value, index) => {
                  {/* ana sayfada en fazla 3 tane product gözüksün */
                  }
                  if (index >= 3) {
                    return '';
                  }
                  return (
                    <div key={index} className="col-12 col-md-6 col-lg-4">
                      <a href="#">
                        <div className="card__primary">
                          <div className="card__primary-img">
                            <img src={"data:image/png;base64, " + value?.image} alt={value?.title} />
                          </div>
                          <div className="d-flex align-items-center gap-2 justify-content-between">
                            <span className="card__primary-title">{value?.title}</span>
                            <span className="card__primary-price">{value?.price}</span>
                          </div>
                          <p className="card__primary-description">{value?.description}</p>
                          <a type="button"
                            href={value?.link}
                            className="btn btn-dark card__primary-btn">
                            {t("buy-now")}
                            <img src="/assets/icons/right.svg" height="16" alt="right" />
                          </a>
                        </div>
                      </a>
                    </div>
                  )
                })
              }

            </div>
          </div>
        </section>

        <section id="founder" data-aos="fade-left" className="founder">
          <div className="container">
            <div className="founder__wrapper">
              <div className="founder__about">
                <h4 className="founder__headline">{t('word-founder')}</h4>
                <p className="founder__description">
                  {t('word-founder-1')}
                  <br />
                  {t('word-founder-2')}
                </p>
                <div className="founder__signature">
                  <img width={"250"} height={"105"} src="/assets/images/durovic-signature.png" alt="signature" />
                </div>
                <span className="founder__title">Djordjije Durovic - CEO Asferrari</span>
              </div>
              <div className="founder__img">
                <img src="/assets/images/founder.png" alt="foundert picture" />
              </div>
            </div>
          </div>
        </section>

        <section id="references" data-aos="fade-right" className="references">
          <div className="container">
            <div className="swiper referencesSwiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide references__item">
                  <img src="/assets/images/ferrari.png" alt="ferrari logo" />
                </div>
                <div className="swiper-slide references__item">
                  <img src="/assets/images/tesla.png" alt="tesla logo" />
                </div>
                <div className="swiper-slide references__item">
                  <img src="/assets/images/maserati.png" alt="maserati logo" />
                </div>
                <div className="swiper-slide references__item">
                  <img src="/assets/images/bugatti.png" alt="bugatti logo" />
                </div>
                <div className="swiper-slide references__item">
                  <img src="/assets/images/lamborghini-alt.png" alt="lamborghini logo" />
                </div>
                <div className="swiper-slide references__item">
                  <img src="/assets/images/land-rover.png" alt="land rover logo" />
                </div>
                <div className="swiper-slide references__item">
                  <img src="/assets/images/bmw.png" alt="bmw logo" />
                </div>
                <div className="swiper-slide references__item">
                  <img src="/assets/images/audi.png" alt="audi logo" />
                </div>
                <div className="swiper-slide references__item">
                  <img src="/assets/images/mercedes-benz-alt.png" alt="mercedes benz logo" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default Home